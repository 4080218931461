<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Detail Registrasi PUS-WUS'">
          <template v-slot:body>
            <div class="row align-items-center">

              <!-- Profile Image -->
              <div class="col-md-4">
                <img
                  class="image"
                  height="200"
                  src="/img/default/posyandu.svg"
                  @load="onImageLoad()"
                />
              </div>

              <!-- Table Data -->
              <div
                class="col"
                v-if="imgLoaded"
              >
                <table class="table mb-3">
                  <tr>
                    <td style="width: 25%"><strong>NIK Istri</strong></td>
                    <td>{{ data.registration_number }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Nama Istri</strong></td>
                    <td>{{ data.name }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Tanggal Lahir Istri</strong></td>
                    <td>{{ data.birt_date_display }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Golongan Darah Istri</strong></td>
                    <td>{{ data.blood_type_name }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Nama Suami</strong></td>
                    <td>{{ data.husband_name }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Nik Suami</strong></td>
                    <td>{{ data.husband_registration_number }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Tanggal Lahir Suami</strong></td>
                    <td>{{ data.husband_birt_date }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Golongan Darah Suami</strong></td>
                    <td>{{ data.husband_blood_type_name }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Sedang Hamil</strong></td>
                    <td>{{ data.is_pregnant_display }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Berat Badan Ibu Hamil (Kg)</strong></td>
                    <td>{{ data.weight }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Tanggal Terakhir Haid</strong></td>
                    <td>{{ data.last_menstruation_display }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>HPL</strong></td>
                    <td>{{ data.hpl_display }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Tanggal Melahirkan</strong></td>
                    <td>{{ data.delivery_date_display }}</td>
                  </tr>
                  <tr>
                    <td><strong>Keterangan</strong></td>
                    <td>{{ data.description }}</td>
                  </tr>
                  <tr>
                    <td><strong>Mendapat KIA</strong></td>
                    <td>{{ data.is_kia_display }}</td>
                  </tr>
                  <tr>
                    <td><strong>Mendapat Tablet Besi</strong></td>
                    <td>{{ data.is_p14_display }}</td>
                  </tr>
                  <tr>
                    <td><strong>Mendapat Imunisasi</strong></td>
                    <td>{{ data.immunization_type_name}}</td>
                  </tr>
                  <tr>
                    <td><strong>Sedang Menyusui</strong></td>
                    <td>{{ data.is_breestfeeding_display}}</td>
                  </tr>
                  <tr>
                    <td><strong>Jenis Akseptor yang digunakan</strong></td>
                    <td>{{ data.acceptor_type_name}}</td>
                  </tr>
                </table>

                <button
                  v-if="btn"
                  @click="$router.push({path: '/mothers/edit/' + data.id})"
                  class="btn mx-1 btn-success" 
                ><i class="far fa-fw fa-edit"></i> Edit</button>
                <button
                  v-if="btn"
                  @click="deleteData"
                  class="btn mx-1 btn-danger"
                  
                ><i class="far fa-fw fa-trash-alt"></i> Hapus</button>
              </div>

            </div>
            <button
              @click="
                  $router.push({
                    path: '/mothers',
                  })
                "
              class="btn mx-1 btn-info mt-5"
            >
              Tutup
            </button>
          </template>
        </Card>
      </div>

    </div>

    <TableChildren :motherId="$route.params.id"/>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import TableChildren from '@/view/pages/children/List.vue'
export default {

  data() {
    return {
      // Data
      data: {
        name:"",
        registration_number:"",
        address:"",
        blood_type_id:"",
        die_date:null,
        description:"",
      },
      // Other
      imgLoaded: true,
      // user access
      btn: false,
    }
  },

  components: {
    Card,
    TableChildren,
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true
    },

    async deleteData() {
      let result = await module.delete('/api/mothers/' + this.$route.params.id)
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push('/mothers/list')
      }
    },

    async get() {
      this.data = await module.get('/api/mothers/' + this.$route.params.id)
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('/mothers/list')
      }
    },

    setActiveButton(){
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for(a = 0; a < access_right.length; a++){
        if(access_right[a] == "2004"){
          this.btn = true
        }
      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Registrasi PUS-WUS", route: "/mothers" },
      { title: "Detail" },
    ])
    // Get Data
    this.get()
    this.setActiveButton()
  },
}

</script>